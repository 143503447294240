// eslint-disable-next-line import/prefer-default-export

export const daysUntilDate = (date: number): number => {
  const currentDate = new Date();
  const comparisonDate = new Date(date * 1000);

  if (comparisonDate < currentDate) return 0;

  const diffTime = comparisonDate.getTime() - currentDate.getTime();
  const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
  return diffDays;
};

export const defaultStartYear = 1930;

export const dateDifferenceInHours = (fromDate: Date, toDate: Date): number => {
  const diffInHours = Math.floor(Math.abs(toDate.getTime() - fromDate.getTime()) / 3600000);

  return diffInHours;
};

export const getDay = (date: Date = new Date()): number => date.getUTCDate();

export const getMonth = (date: Date = new Date()): number => date.getMonth();

export const getWeek = (date: Date = new Date()): number => {
  const yearStart = +new Date(date.getFullYear(), 0, 1);
  const today = +new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const dayOfYear = (today - yearStart + 1) / 86400000;
  const week = Math.ceil(dayOfYear / 7);

  return week;
};

export const getYear = (date: Date = new Date()): number => date.getFullYear();

export const labelAgo = (label: string, value: number) => {
  const intervalValue = Math.floor(value);
  return intervalValue === 1 ? `1 ${label} ago` : `${intervalValue} ${label}s ago`;
};

export const timeAgo = (date: string) => {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) return labelAgo('year', interval);
  interval = seconds / 2592000;
  if (interval > 1) return labelAgo('month', interval);
  interval = seconds / 86400;
  if (interval > 1) return labelAgo('day', interval);
  interval = seconds / 3600;
  if (interval > 1) return labelAgo('hour', interval);
  interval = seconds / 60;
  if (interval > 1) return labelAgo('minute', interval);
  return labelAgo('second', interval);
};

export const getLocalIsoDateTime = (dtString: string) => {
  try {
    if (dtString === '') return '';
    const offset = new Date().getTimezoneOffset();
    const absO = Math.abs(offset);
    const sign = offset < 0 ? '+' : '-';
    const no1 = Math.floor(absO / 60)
      .toString()
      .split('')
      .slice(-2);
    const no2 = (absO % 60).toString().split('').slice(-2);

    const zone = `${sign}${no1.length > 1 ? '' : 0}${no1}:${no2.length > 1 ? '' : 0}${no2}`;
    return dtString + zone;
  } catch (e) {
    return '';
  }
};

export const formatDate = (dateFormat: string, date = new Date(), timeZone = ''): string => {
  try {
    switch (dateFormat) {
      case 'MMMM D, YYYY': {
        return new Intl.DateTimeFormat('en-CA', {
          dateStyle: 'long',
          ...(timeZone && { timeZone }),
        }).format(date);
      }
      // get local time with 12hrs format
      case 'LT': {
        return new Intl.DateTimeFormat('en-US', {
          timeStyle: 'short',
          ...(timeZone && { timeZone }),
        }).format(date);
      }

      case 'MMM DD YYYY': {
        return new Intl.DateTimeFormat('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          ...(timeZone && { timeZone }),
        })
          .format(date)
          .replace(',', '');
      }
      default: {
        return date.toISOString();
      }
    }
  } catch (error) {
    return '';
  }
};

export const dayOfWeek = (): string => {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date();

  return weekday[today.getDay()];
};

export const generateYearsInRange = (startYear: number, endYear: number) =>
  Array.from({ length: endYear - startYear + 1 }, (item, i) => startYear + i);

export const generateDaysForMonth = (month: number, year: number) =>
  Array.from({ length: new Date(year, month, 0).getDate() }, (item, i) => i + 1);

export const getNumericMonths = () => Array.from({ length: 12 }, (item, i) => i + 1);

export const getMonthName = (monthNum: number) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthNum - 1];
};

export const validateYear = (year: number) => {
  const thisYear = getYear();

  if (defaultStartYear > year || thisYear < year) {
    return false;
  }

  return true;
};

const isInFuture = (date: Date) => date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);

export const validateMonth = (param: string, month: string) => {
  const parsedMonth = parseInt(month, 10);
  if (Number.isNaN(parsedMonth) || parsedMonth < 1 || parsedMonth > 12) {
    return false;
  }

  const requestedDate = new Date(`${param}-${month}-1`);

  if (isInFuture(requestedDate)) {
    return false;
  }
  return true;
};

export const validateDay = (param: string, month: string, day: string): boolean => {
  const parsedDay = parseInt(day, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedYear = parseInt(param, 10);

  if (
    Number.isNaN(parsedDay) ||
    Number.isNaN(parsedMonth) ||
    Number.isNaN(parsedYear) ||
    parsedDay < 1 ||
    parsedDay > 31 ||
    parsedMonth < 1 ||
    parsedMonth > 12
  ) {
    return false;
  }

  const requestedDate = new Date(`${param}-${month}-${day}`);
  const isValidDay = requestedDate.getDate() === parsedDay && requestedDate.getMonth() + 1 === parsedMonth;

  if (!isValidDay || requestedDate.getFullYear() !== parsedYear) {
    return false;
  }

  const currentDate = new Date();
  const inFuture = requestedDate.setHours(0, 0, 0, 0) > currentDate.setHours(0, 0, 0, 0);

  return !inFuture;
};

export const secondsToMinAndSeconds = (sec: number) => {
  const minutes = Math.floor(sec / 60);
  const seconds = Math.round(sec - minutes * 60);
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${formattedSeconds}`;
};

export function yearFrom(yearsAgoCount: number): number {
  const currentYear = new Date().getFullYear();
  const startingYear = currentYear - yearsAgoCount;
  return startingYear;
}

export type DateTimeFormat = 'date' | 'datetime';

/**
 * Replaces space in the middle of timestamp string, if it exists, with a 'T'.
 *
 * @param timestamp 'YYYY-MM-DD 00:00:00Z' | 'YYYY-MM-DDT00:00:00Z'
 * @returns string 'YYYY-MM-DDT00:00:00Z'
 */
const standardizeTimestamp = (timestamp: string): string => timestamp.replace(/ /g, 'T');

/**
 * Standardizes timestamp string and then formats it to display readable
 * date or date and time based on fmt param.
 *
 * @param timestamp 'YYYY-MM-DD 00:00:00Z' | 'YYYY-MM-DDT00:00:00Z'
 * @param fmt 'datetime' | 'date'
 * @returns '00 Month, YYYY, 00:00 AM/PM EST' | '00 Month, YYYY'
 */
export const formatDateTime = (timestamp: string, fmt: DateTimeFormat): string => {
  let formattedTimeStamp = '';
  try {
    const standardizedStamp = standardizeTimestamp(timestamp);
    switch (fmt) {
      case 'datetime':
        formattedTimeStamp = new Date(standardizedStamp).toLocaleString('en-US', {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'long',
          timeZoneName: 'short',
          year: 'numeric',
        });
        break;
      case 'date':
        formattedTimeStamp = new Date(standardizedStamp).toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
        break;
      default:
        break;
    }
    /* eslint-disable no-empty*/
  } catch (error) {}
  return formattedTimeStamp;
};

interface SitemapParams {
  param: string;
  month?: string;
  day?: string;
}
export const sitemapQuickPaths = ['today', 'yesterday', 'thisweek', 'lastweek'];
export function validateDateParams(context: SitemapParams) {
  const { param, month, day } = context;
  const parsedParam = parseInt(param, 10);
  if (Number.isNaN(parsedParam) && !sitemapQuickPaths.includes(param)) {
    return false;
  }

  if (!validateYear(parsedParam)) {
    return false;
  }

  if (month && !validateMonth(param, month)) {
    return false;
  }

  if (month && day && !validateDay(param, month, day)) {
    return false;
  }

  return true;
}
